import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { useDeviceTypes } from "halifax";
import { Route } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import clsx from "clsx";

import { CarBookConnectorProps } from "./container";
import {
  PATH_BOOK,
  PATH_BOOK_CONFIRMATION,
  PATH_SHOP,
} from "../../utils/paths";
import "./styles.scss";
import { BookingErrorModal } from "./components/BookingErrorModal";
import { BookingInProgressModal } from "./components/BookingInProgressModal";
import { BookingSuccessModal } from "./components/BookingSuccessModal";
import { CoreCarsCheckout, REVIEWED_CAR_DETAILS_CHECKOUT } from "redmond";
import { trackEvent } from "../../api/v1/analytics/trackEvent";
import { CAR_CONFIRMATION_TITLE, PORTAL_TITLE } from "../../lang/textConstants";
import { config } from "../../api/config";
import { BookingSubmitApprovalModal } from "./components/capone-corporate/BookingSubmitApprovalModal";
import { BookingSubmitApprovalErrorModal } from "./components/capone-corporate/BookingSubmitApprovalErrorModal";
import { isCorpTenant } from "@capone/common";
import { MobileCarBookWorkflowStep } from "./components/capone/MobileCarBookWorkflow/component";
import { useDispatch } from "react-redux";
import { setCarShopResults, setSelectedVehicle } from "../shop/actions/actions";
import { getCoreCarAvailabilityLineItem } from "../availability/reducer/utils/carAvailabilityHelperFunctions";
import { CarExtraInfoCallState } from "../shop/reducer";

const { DesktopCarBookWorkflow } = isCorpTenant(config.TENANT)
  ? require("./components/capone-corporate/DesktopCarBookWorkflow")
  : require("./components/capone/DesktopCarBookWorkflow");
const { MobileCarBookWorkflow } = isCorpTenant(config.TENANT)
  ? require("./components/capone-corporate/MobileCarBookWorkflow")
  : require("./components/capone/MobileCarBookWorkflow");

export interface ICarBookProps
  extends RouteComponentProps,
    CarBookConnectorProps {
  core?: CoreCarsCheckout;
}

export const CarBook = (props: ICarBookProps) => {
  const {
    location,
    history,
    selectedVehicle,
    isBookingValid,
    reviewedCarDetailsCheckoutProperties,
    setPriceQuote,
    core,
  } = props;
  const { matchesMobile, matchesDesktop } = useDeviceTypes();
  const [checkoutStep, setCheckoutStep] =
    useState<MobileCarBookWorkflowStep>(0);

  const dispatch = useDispatch();

  useEffect(() => {
    if (core) {
      const vehicle = getCoreCarAvailabilityLineItem(
        core.car,
        core.context,
        null,
        "",
        0
      );
      dispatch(setSelectedVehicle(vehicle));
      dispatch(
        setCarShopResults({
          carExtraInfoCallState: CarExtraInfoCallState.Success,
          // @ts-ignore
          extraInfo: core.shop,
        })
      );
    }
  }, []);

  useEffect(() => {
    document.title = CAR_CONFIRMATION_TITLE;

    return () => {
      document.title = PORTAL_TITLE;
    };
  }, []);

  useEffect(() => {
    setPriceQuote(null);
    if (!selectedVehicle && core == null) {
      history.push(`${PATH_SHOP}${history.location.search}`);
    }
  }, [selectedVehicle]);

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 0);
  }, [location.pathname]);

  useEffect(() => {
    if (isBookingValid) {
      trackEvent({
        eventName: REVIEWED_CAR_DETAILS_CHECKOUT,
        properties: { ...reviewedCarDetailsCheckoutProperties.properties },
        encryptedProperties: [
          ...reviewedCarDetailsCheckoutProperties.encryptedProperties,
        ],
      });
    }
  }, [isBookingValid]);

  return (
    <Box
      className={clsx(
        "car-book-root",
        { confirm: location.pathname === PATH_BOOK_CONFIRMATION },
        { mobile: matchesMobile }
      )}
    >
      <Route path={PATH_BOOK} exact>
        <>
          <Box className="car-book-container">
            {matchesDesktop && selectedVehicle && <DesktopCarBookWorkflow />}
            {matchesMobile && selectedVehicle && (
              <MobileCarBookWorkflow
                checkoutStep={checkoutStep}
                setCheckoutStep={setCheckoutStep}
              />
            )}
          </Box>
          <BookingSubmitApprovalModal />
          <BookingSubmitApprovalErrorModal />
          <BookingErrorModal setCheckoutStep={setCheckoutStep} core={core} />
          <BookingInProgressModal />
        </>
      </Route>
      <Route path={PATH_BOOK_CONFIRMATION}>
        <BookingSuccessModal />
      </Route>
    </Box>
  );
};
