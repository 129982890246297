import { all } from "redux-saga/effects";
import { sagas as carSearch } from "../modules/search";
import { sagas as carAvailability } from "../modules/availability";
import { sagas as carRewards } from "../modules/rewards";
import { sagas as carShop } from "../modules/shop";
import { sagas as carBook } from "../modules/book";
import { sagas as carTravelWallet } from "../modules/travel-wallet";

export function* rootSaga() {
  yield all([
    carSearch.watchFetchDropOffLocationAutocomplete(),
    carSearch.watchFetchPickUpLocationAutocomplete(),

    carAvailability.watchFetchCarsAvailability(),
    carAvailability.watchListCarsAvailabilityPaymentMethods(),

    carShop.watchFetchCarsShopSaga(),

    carRewards.watchFetchRewardsAccounts(),

    carBook.watchFetchUserPassengers(),
    carBook.watchDeletePassengerSaga(),
    carBook.watchUpdateUserPassenger(),
    carBook.watchFetchCorpUserPassengers(),

    carBook.watchFetchPaymentMethodSaga(),
    carBook.watchDeletePaymentMethodSaga(),
    carBook.watchListPaymentMethodsSaga(),
    carBook.watchVerifyPaymentMethodSaga(),

    carBook.watchProductToEarnSaga(),
    carBook.watchFetchAllEarnForProductSaga(),

    // Corporate Travel
    carBook.watchSubmitForApprovalSaga(),

    carBook.watchSchedulePriceQuoteSaga(),
    carBook.watchPollPriceQuoteSaga(),
    carBook.watchscheduleBookSaga(),
    carBook.watchPollConfirmationDetailsSaga(),

    carBook.watchFetchApplicableTravelWalletItemsSaga(),

    carTravelWallet.watchFetchTravelWalletDetails(),
    carTravelWallet.watchFetchTravelWalletCreditHistorySaga(),
  ]);
}
