import React, { useContext } from "react";
import { Box, Link, Typography } from "@material-ui/core";
import ReactList from "react-list";
import {
  CarDetailsCard,
  useDeviceTypes,
  GenericShopListFooter,
  Icon,
  IconName,
  TravelSalesEventBanner,
  OutOfPolicyModal,
  CorpPolicyBanner,
} from "halifax";
import clsx from "clsx";
import { History } from "history";

import { DesktopCarAvailabilityConnectorProps } from "./container";
import {
  transformToStringifiedAvailabilityQuery,
  transformToStringifiedShopQuery,
} from "../../../availability/utils/queryStringHelpers";
import * as textConstants from "./textConstants";
import {
  PATH_AVAILABILITY,
  PATH_SHOP,
  PATH_TRAVEL_SALE,
} from "../../../../utils/paths";
import "./styles.scss";
import {
  SelectedTravelOfferScreen,
  SELECTED_CAR_FROM_LIST,
  SELECTED_TRAVEL_OFFER,
  ModalScreens,
  POLICY_DESCRIPTOR,
  PolicyDescriptorEntryPoints,
  PolicyViolation,
  VIEWED_POLICY_DESCRIPTOR,
  ENGAGED_OFFER_CTA,
} from "redmond";
import { trackEvent } from "../../../../api/v1/analytics/trackEvent";
import { DesktopCalendarPicker } from "../../../search/components/DesktopCarSearchControl/components/DesktopCalendarPicker";
import { RouteComponentProps } from "react-router";
import queryStringParser from "query-string";
import dayjs from "dayjs";
import {
  AVAILABLE,
  CARS_CX_V1,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  GLOBAL_MOBILE_NAV_EXPERIMENT,
  TRAVEL_SALE,
  TRAVEL_SALE_ACTIVE,
  TRAVEL_SALE_VARIANTS,
  TRAVEL_WALLET_OFFER_EXPERIMENT,
  useExperiments,
} from "../../../../context/experiments";
import {
  TRAVEL_SALES_EVENT_ACTIVE_SUBTITLE,
  TRAVEL_SALES_EVENT_ACTIVE_CTA,
} from "../../../travel-wallet/components/TravelWalletDrawer/constants";
import { ClientContext } from "../../../../App";
import { config } from "../../../../api/config";
import {
  getClickCancelOOPModalEvent,
  getClickContinueOOPModalEvent,
  getShowOOPModalEvent,
  isCorpTenant,
  useShowPolicyBanner,
} from "@capone/common";
import { ICorpCarAvailabilityLineItem } from "../../reducer/utils/carAvailabilityHelperFunctions";
import { useExperimentIsVariant } from "@capone/experiments";

export interface ICarAvailabilityList
  extends DesktopCarAvailabilityConnectorProps,
    RouteComponentProps {
  onAdjustDateClick?: () => void;
}

const DESKTOP_APPROXIMATE_CAR_AVAILABILITY_CARD_HEIGHT = 300;
const MOBILE_APPROXIMATE_CAR_AVAILABILITY_CARD_HEIGHT = 336;

export const CarAvailabilityList = (props: ICarAvailabilityList) => {
  const {
    carAvailabilityLineItems,
    hasCompletedRequest,
    isLoadingFirstBatch,
    isReadyToFetchMoreCars,
    fetchMoreCarsAvailability,
    resetFilters,
    setSelectedVehicleId,
    setSelectedVehicle,
    carShopQueryParams,
    selectedCarFromListProperties,
    history,
    setOpenDatesModal,
    openDatesModal,
    onAdjustDateClick,
    availabilityRequestParameters,
    largestValueAccount,
    canEarnRewards,
  } = props;
  const listRef = React.useRef<ReactList | null>(null);
  const divRef = React.useRef<HTMLDivElement | null>(null);
  const { matchesMobile } = useDeviceTypes();
  const [untilProp, setUntilProp] = React.useState<Date | null>(null);
  const [fromProp, setFromProp] = React.useState<Date | null>(null);
  const [refetchFromCalendarModal, setRefetchFromCalendarModal] =
    React.useState(false);
  const [appliedFilters, setAppliedFilters] = React.useState<string[]>([]);
  const [showPolicyModal, setShowPolicyModal] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [corpPolicyDescriptorViewCount, setCorpPolicyDescriptorViewCount] =
    React.useState(0);

  const expState = useExperiments();

  const { policies, isAutoApprovalEnabled } =
    useContext(ClientContext);

  const maxPricePerDay =
    policies?.cars.policies[0]?.maxPricePerDay ?? undefined;

  const travelWalletOffer = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_OFFER_EXPERIMENT
  );
  const isTravelWalletOfferExperiment = React.useMemo(
    () => travelWalletOffer === AVAILABLE,
    [travelWalletOffer]
  );

  const carsCXV1Experiment = getExperimentVariant(
    expState.experiments,
    CARS_CX_V1
  );
  const isCarsCXV1Experiment = React.useMemo(
    () => carsCXV1Experiment === AVAILABLE,
    [carsCXV1Experiment]
  );

  const isApprovalsV2Enabled = useExperimentIsVariant(
    "corp-approvals-v2",
    "m2"
  );

  const isCorporate = isCorpTenant(config.TENANT);

  const outOfPolicyCopy =
    isAutoApprovalEnabled || isApprovalsV2Enabled
      ? "If you wish to proceed with your selection, admins will be notified upon booking that this car was out of policy."
      : "This car rate is out of your company policy. You can continue anyway or change your selection.";
  const modalType = isAutoApprovalEnabled
    ? "out_of_policy_auto"
    : isApprovalsV2Enabled
    ? "out_of_policy_24hr_review"
    : "out_of_policy";

  const travelSalesEventVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    TRAVEL_SALE,
    TRAVEL_SALE_VARIANTS
  );

  const isGlobalMobileNavExperiment =
    getExperimentVariant(expState.experiments, GLOBAL_MOBILE_NAV_EXPERIMENT) ===
    AVAILABLE;

  const showEarnEnhancement =
    !!largestValueAccount &&
    !!largestValueAccount.earn.carsMultiplier &&
    canEarnRewards;

  const onHandleClick = (index: number, isInPolicy: boolean = true) => {
    const car = carAvailabilityLineItems[index];
    setSelectedVehicleId(car.id as string);
    setSelectedVehicle(car);
    trackEvent({
      eventName: SELECTED_CAR_FROM_LIST,
      ...selectedCarFromListProperties,
    });

    if (!isInPolicy && !showPolicyModal && isCorporate) {
      setShowPolicyModal(true);
      setSelectedIndex(index);
      trackEvent(
        getShowOOPModalEvent(ModalScreens.CARS_AVAILABILITY, "cars", modalType)
      );
      return;
    }

    const params = transformToStringifiedShopQuery({
      vehicleId: car.id as string,
      ...carShopQueryParams,
    });
    // TODO fix when we have stable ids
    history.push(`${PATH_SHOP}${params}`);
    // if (matchesMobile) history.push(`${PATH_SHOP}${params}`);
    // else window.open(`${PATH_SHOP}${params}`, "_blank", "noopener");
  };

  const onContinueClick = () => {
    setShowPolicyModal(false);
    onHandleClick(selectedIndex);
    trackEvent(
      getClickContinueOOPModalEvent(
        ModalScreens.CARS_AVAILABILITY,
        "cars",
        modalType
      )
    );
  };

  const onOpenPolicyDescriptor = (reasons: PolicyViolation[]) => {
    if (corpPolicyDescriptorViewCount <= 5) {
      trackEvent({
        eventName: VIEWED_POLICY_DESCRIPTOR,
        properties: {
          type: POLICY_DESCRIPTOR,
          entry_point: PolicyDescriptorEntryPoints.CARS_LIST,
          funnel: "cars",
          policy_reason: reasons.join(", "),
        },
      });
      setCorpPolicyDescriptorViewCount((prevState) => prevState + 1);
    }
  };

  React.useEffect(() => {
    const appliedFilters = Object.keys(props.filters).filter(
      (key) => props.filters[key]
    );
    setAppliedFilters(appliedFilters);
  }, [props.filters]);

  React.useEffect(() => {
    const parsedQueryStringPrimitive = queryStringParser.parse(
      history.location.search
    );
    setUntilProp(
      dayjs(parsedQueryStringPrimitive.dropOffDate as string).toDate()
    );
    setFromProp(
      dayjs(parsedQueryStringPrimitive.pickUpDate as string).toDate()
    );
  }, [history.location.search]);

  const {
    dropOffDate,
    dropOffTime,
    dropOffLocation,
    pickUpDate,
    pickUpTime,
    pickUpLocation,
    driverAge,
  } = availabilityRequestParameters;
  React.useEffect(() => {
    if (location && dropOffDate && pickUpDate && refetchFromCalendarModal) {
      history.push(
        `${PATH_AVAILABILITY}${transformToStringifiedAvailabilityQuery(
          dropOffDate!,
          dropOffTime!,
          dropOffLocation!,
          pickUpDate!,
          pickUpTime!,
          pickUpLocation!,
          driverAge!
        )}`
      );
      setRefetchFromCalendarModal(false);
    }
  }, [dropOffDate, pickUpDate]);

  const showPolicyBanner = useShowPolicyBanner(policies);

  return (
    <Box
      className={clsx("car-availability-list-root", { mobile: matchesMobile })}
    >
      <Box className="car-availability-list-container">
        <Box className="car-availability-list-header">
          <Box className="header-container">
            <span
              aria-atomic
              aria-live="polite"
              id="car-rentals-alert"
              className="sr-only"
            >
              {`${carAvailabilityLineItems.length} cars found`}
            </span>
            <Typography className="car-rentals-text" variant="h5">
              {textConstants.SHOWING_TEXT}{" "}
              <strong>
                {carAvailabilityLineItems.length}{" "}
                {isCarsCXV1Experiment
                  ? textConstants.CAR_LIST_COUNT_TITLE
                  : textConstants.CAR_RENTALS_TEXT}
              </strong>
            </Typography>
            <Typography className="best-price-text" variant="body2">
              {textConstants.SORTED_BY_BEST_PRICE_TEXT}
            </Typography>
          </Box>
        </Box>
        {travelSalesEventVariant === TRAVEL_SALE_ACTIVE && (
          <TravelSalesEventBanner
            variant="default"
            onClick={() => {
              const path = `${PATH_TRAVEL_SALE}?entryType=cars_list_component`;
              matchesMobile ? history.push(path) : window.open(path, "_blank");
            }}
            subtitle={TRAVEL_SALES_EVENT_ACTIVE_SUBTITLE}
            buttonText={TRAVEL_SALES_EVENT_ACTIVE_CTA}
          />
        )}
        <div ref={divRef} className="availability-list">
          <ReactList
            ref={listRef}
            itemRenderer={(index: number) => {
              if (index < carAvailabilityLineItems.length) {
                const carAvailabilityItem = carAvailabilityLineItems[index];
                const corporateTravel = (
                  carAvailabilityItem as ICorpCarAvailabilityLineItem
                ).corporateTravel;
                const { isInPolicy = false, reasons = [] } =
                  corporateTravel?.policyCompliance ?? {};

                const bestOffer = carAvailabilityLineItems[index].offer;

                return (
                  <Box key={index} className="outer-car-details-card-wrapper">
                    <Link
                      className={clsx("car-details-card-wrapper", {
                        "before-find-more-results":
                          index === carAvailabilityLineItems.length - 1 &&
                          hasCompletedRequest,
                        "in-policy": showPolicyBanner && isInPolicy,
                        "global-mobile-nav": isGlobalMobileNavExperiment,
                      })}
                      onClick={() => {
                        isCarsCXV1Experiment ? onHandleClick(index) : undefined;
                      }}
                    >
                      <CorpPolicyBanner
                        variant={matchesMobile ? "base" : "descriptor"}
                        productType="car"
                        corporateTravel={
                          showPolicyBanner ? corporateTravel : undefined
                        }
                        limit={maxPricePerDay}
                        onOpen={() => onOpenPolicyDescriptor(reasons)}
                      />
                      <Box
                        className={clsx(
                          "car-details-content",
                          matchesMobile && "mobile"
                        )}
                      >
                        <CarDetailsCard
                          {...carAvailabilityLineItems[index]}
                          onClick={() => onHandleClick(index, isInPolicy)}
                          type="content"
                          isMobile={matchesMobile}
                          className={clsx("b2b", config.TENANT, {
                            "includes-earn": showEarnEnhancement,
                            "cars-cx-v1": isCarsCXV1Experiment,
                          })}
                          bestOffer={bestOffer}
                          showOffer={isTravelWalletOfferExperiment}
                          onOfferTooltipSetOpenModal={(open) => {
                            if (open) {
                              trackEvent({
                                eventName: SELECTED_TRAVEL_OFFER,
                                properties: {
                                  screen: SelectedTravelOfferScreen.CARS_SHOP,
                                  ...bestOffer?.trackingPropertiesV2
                                    ?.properties,
                                },
                                encryptedProperties: [
                                  bestOffer?.trackingPropertiesV2
                                    ?.encryptedProperties ?? "",
                                ],
                              });

                              trackEvent({
                                eventName: ENGAGED_OFFER_CTA,
                                properties: {
                                  location: SelectedTravelOfferScreen.CARS_SHOP,
                                  entry_type: "tooltip",
                                  funnel: bestOffer?.funnels.join(","),
                                  offer_name:
                                    bestOffer?.trackingPropertiesV2?.properties
                                      ?.offer_name,
                                },
                                encryptedProperties: [
                                  bestOffer?.trackingPropertiesV2
                                    ?.encryptedProperties ?? "",
                                ],
                              });
                            }
                          }}
                          earnTagContent={
                            showEarnEnhancement ? (
                              <>
                                <Icon name={IconName.StarIcon} />
                                <Typography
                                  className="earn-tag-text"
                                  dangerouslySetInnerHTML={{
                                    __html: textConstants.getEarnTagText(
                                      largestValueAccount.earn.carsMultiplier,
                                      largestValueAccount.rewardsBalance
                                        .currencyDescription ??
                                        largestValueAccount.rewardsBalance
                                          .currency
                                    ),
                                  }}
                                />
                              </>
                            ) : undefined
                          }
                          earnTagClassName={
                            showEarnEnhancement ? "b2b" : undefined
                          }
                          isCarsCXV1Experiment={isCarsCXV1Experiment}
                        />
                      </Box>
                    </Link>
                  </Box>
                );
              } else if (hasCompletedRequest) {
                return (
                  <Box
                    className="availability-list-find-more-results-footer-container"
                    key={index}
                  >
                    <DesktopCalendarPicker
                      open={openDatesModal}
                      closePopup={() => {
                        setOpenDatesModal(false);
                      }}
                      fromProp={fromProp}
                      untilProp={untilProp}
                      onClickDone={() => setRefetchFromCalendarModal(true)}
                    />
                    <GenericShopListFooter
                      className={clsx(
                        "availability-list-find-more-results",
                        "b2b"
                      )}
                      title={textConstants.FIND_MORE_RESULTS_TITLE_TEXT}
                      subtitle={
                        appliedFilters.length > 0
                          ? textConstants.FIND_MORE_RESULTS_WITH_FILTERS_SUBTITLE_TEXT
                          : textConstants.FIND_MORE_RESULTS_SUBTITLE_TEXT
                      }
                      buttons={
                        appliedFilters.length > 0
                          ? [
                              {
                                title: textConstants.RESET_FILTERS_TEXT,
                                className: clsx(
                                  "find-more-results-button",
                                  "reset-filters",
                                  "b2b"
                                ),
                                isPrimary: false,
                                onClick: () => {
                                  resetFilters();
                                  window.scrollTo(0, 0);
                                },
                              },
                              {
                                title: textConstants.ADJUST_DATES,
                                className: clsx(
                                  "find-more-results-button",
                                  "search-again",
                                  "b2b"
                                ),
                                isPrimary: true,
                                onClick: () => {
                                  onAdjustDateClick
                                    ? onAdjustDateClick()
                                    : setOpenDatesModal(true);
                                },
                              },
                            ]
                          : [
                              {
                                title: textConstants.ADJUST_DATES,
                                className: clsx(
                                  "find-more-results-button",
                                  "search-again",
                                  "b2b"
                                ),
                                isPrimary: true,
                                onClick: () => {
                                  onAdjustDateClick
                                    ? onAdjustDateClick()
                                    : setOpenDatesModal(true);
                                },
                              },
                            ]
                      }
                      isMobile={matchesMobile}
                    />
                  </Box>
                );
              } else {
                return (
                  <CarDetailsSkeleton
                    key={index}
                    isReadyToFetchMoreCars={isReadyToFetchMoreCars}
                    fetchMoreCarsAvailability={fetchMoreCarsAvailability}
                    history={history}
                    disableFetchMoreCars={
                      index !== carAvailabilityLineItems.length
                    }
                    isMobile={matchesMobile}
                  />
                );
              }
            }}
            length={
              // note: when it's loading the first batch, showing more skeleton cards makes it less empty
              isLoadingFirstBatch ? 3 : carAvailabilityLineItems.length + 1
            }
            type="variable"
            itemSizeEstimator={() =>
              matchesMobile
                ? MOBILE_APPROXIMATE_CAR_AVAILABILITY_CARD_HEIGHT
                : DESKTOP_APPROXIMATE_CAR_AVAILABILITY_CARD_HEIGHT
            }
          />
        </div>
        {isCorporate && (
          <OutOfPolicyModal
            subtitle={outOfPolicyCopy}
            isMobile={matchesMobile}
            isOpen={showPolicyModal}
            onClose={() => {
              setShowPolicyModal(false);
              trackEvent(
                getClickCancelOOPModalEvent(
                  ModalScreens.CARS_AVAILABILITY,
                  "cars",
                  modalType
                )
              );
            }}
            onContinue={onContinueClick}
            isApprovalRequired={
              policies?.settings && policies.settings.isApprovalRequired
            }
          />
        )}
      </Box>
    </Box>
  );
};

interface ICarDetailsSkeletonProps {
  isReadyToFetchMoreCars: boolean;
  fetchMoreCarsAvailability: (history: History<unknown>) => void;
  history: History<unknown>;
  disableFetchMoreCars: boolean;
  isMobile: boolean;
}

const CarDetailsSkeleton = (props: ICarDetailsSkeletonProps) => {
  const {
    isReadyToFetchMoreCars,
    fetchMoreCarsAvailability,
    history,
    disableFetchMoreCars,
    isMobile,
  } = props;

  React.useEffect(() => {
    if (!disableFetchMoreCars && isReadyToFetchMoreCars) {
      fetchMoreCarsAvailability(history);
    }
  }, [
    isReadyToFetchMoreCars,
    fetchMoreCarsAvailability,
    history,
    disableFetchMoreCars,
  ]);

  return (
    <Box className={clsx("outer-car-details-card-wrapper", "skeleton")}>
      <Box className="car-details-card-wrapper">
        <CarDetailsCard type="skeleton" isMobile={isMobile} />
      </Box>
    </Box>
  );
};
